import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import SignOutButton from '../SignOut';
import * as ROUTES from "../../constants/routes";
import { compose } from "recompose";
import { Button, Col, Dropdown } from "react-bootstrap";
import * as ROLES from "../../constants/roles";
import { authUserSet } from "../../redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCaretDown,
  faCompress,
  faExpand,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const CustomDropdown = React.forwardRef(({ children, onClick }, ref) => (
  <li className="dropdown notification-list" ref={ref}>
    {children}
  </li>
));
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#!"
    className="nav-link dropdown-toggle nav-user arrow-none mr-0"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    // const [value, setValue] = useState("");

    const value = "";

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const toggleSidenavbar = () => {
  const sideCondensedRef = document.querySelector("body");
  sideCondensedRef.setAttribute(
    "data-leftbar-compact-mode",
    sideCondensedRef.getAttribute("data-leftbar-compact-mode") === "condensed"
      ? "default"
      : "condensed"
  );
  document.body.classList.add("sidebar-enable");
  /*localStorage.setItem(data-leftbar-compact-mode,sideCondensedRef.getAttribute("data-leftbar-compact-mode"));*/
};

const Navigation = ({ authUser, authUserSet, history }) => {
  const [bookingId, setBookingId] = useState();
  const [toggleScreen, setToggleScreen] = useState(1);
  const [partnerId, setPartnerId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showInput, setShowInput] = useState(false); // State to toggle input visibility
  const [isPID, setIsPID] = useState(false);

  var elem = document.querySelector("body");


  const handleIconClick = () => {
    setShowInput((prev)=> !prev);
  };

    // Function to handle form submission on Enter key
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {

        if(isPID === true) {
          handleSubmit(e); // Call the submit handler
        } else {
          handleBookingSearch(e)
        }
      }
    };

  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
    setToggleScreen((toggle) => !toggle);
  }
  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setToggleScreen((toggle) => !toggle);
  }
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    window.open(`/edit-cook/${partnerId}`, "_blank"); // redirect on given url on new tab
    setPartnerId("")
  };
  const handleBookingSearch = (event) => {
    event.preventDefault();
    window.open(`/booking-dashboard?customer_query=${bookingId}`);
    setBookingId("");
  };
  const handleOnClick = () => {
    toggleSidenavbar();
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="navbar-custom topnav-navbar  topnav-navbar-dark ">
        {/*left nav icon*/}
        <div className="">
          {/* <!-- LOGO --> */}

          

          <div className="row ">
            <Button
              variant="link"
              className="button-menu-mobile text-white"
              onClick={handleOnClick}
            >
              <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
            </Button>

            <Col md={2} className="d-flex align-items-center justify-content-center" sm={12} xs={4}>
              <div>
                <Link to="/" className="topnav-logo " >
                  <span className="topnav-logo-lg">
                    <img
                      src="/assets/images/coox_admin_updated_logo.png"
                      alt=""
                      height="50"
                    />
                    {/* <h1 className='text-light d-inline'>COOX</h1> */}
                  </span>
                  <span className="topnav-logo-sm align-items-center">
                    <img src="/assets/images/coox_admin_updated_logo.png" alt="" height="30" />
                  </span>
                </Link>
              </div>
            </Col>
            <Col md={1} sm={6} xs={3} className="d-flex justify-content-end align-items-center">
              {(authUser.roles.includes(ROLES.JRADMIN) ||
                authUser.roles.includes(ROLES.SUPERADMIN) ||
                authUser.roles.includes(ROLES.ADMIN) ||
                authUser.roles.includes(ROLES.FINANCE)) && (
                <form onSubmit={handleBookingSearch} >
                  {" "}
                  <div className="input-group ">
                    <div className="input-group-prepend d-none d-md-flex">
                      <span
                        className="input-group-text bg-warning text-white"
                        id="search_partner_id"
                      >
                        <i className="fas fa-search"></i>
                      </span>
                    </div>
                    {/* <input
                      type="number"
                      className="form-control "
                      placeholder="PID"
                      aria-label="Username"
                      aria-describedby="search_partner_id"
                      value={partnerId}
                      onChange={(e) => setPartnerId(e.target.value)}
                    /> */}

                    {/* Icon only visible on mobile devices */}
                    <button
                      type="button" // Use button type to avoid form submission on click
                      className="btn btn-warning d-md-none"
                      style={{marginLeft: -55}}
                      onClick={() => {
                        handleIconClick()
                        setIsPID(true)
                      }} // Show input on click
                      aria-label="Search"
                    >
                      <i className="fas fa-search"></i>
                    </button>

                    {/* Input field visible on larger devices */}
                    <input
                      type="number"
                      className="form-control d-none d-md-block"
                      placeholder="BID"
                      aria-label="Username"
                      aria-describedby="search_partner_id"
                      value={bookingId}
                      onChange={(e) => setBookingId(e.target.value)}
                    />

                    {/* Input field shown on icon click in mobile */}
                  </div>
                </form>
              )}
            </Col>
            <Col md={1} sm={6} xs={0} className="d-none d-sm-flex justify-content-end align-items-center">
              {(authUser.roles.includes(ROLES.JRADMIN) ||
                authUser.roles.includes(ROLES.SUPERADMIN) ||
                authUser.roles.includes(ROLES.ADMIN) || 
                authUser.roles.includes(ROLES.FINANCE)) && (
                <form onSubmit={handleSubmit} >
                  {" "}
                  <div className="input-group ">
                    <div className="input-group-prepend d-none d-md-flex">
                      <span
                        className="input-group-text bg-warning text-white"
                        id="search_partner_id"
                      >
                        <i className="fas fa-search"></i>
                      </span>
                    </div>
                    {/* <input
                      type="number"
                      className="form-control "
                      placeholder="PID"
                      aria-label="Username"
                      aria-describedby="search_partner_id"
                      value={partnerId}
                      onChange={(e) => setPartnerId(e.target.value)}
                    /> */}

                    {/* Icon only visible on mobile devices */}
                    {/* <button
                      type="button" // Use button type to avoid form submission on click
                      className="btn btn-warning d-md-none"
                      style={{marginLeft: -55}}
                      onClick={() => {
                        handleIconClick()
                        setIsPID(true)
                      }} // Show input on click
                      aria-label="Search"
                    >
                      <i className="fas fa-search"></i>
                    </button> */}

                    {/* Input field visible on larger devices */}
                    <input
                      type="number"
                      className="form-control d-none d-md-block"
                      placeholder="PID"
                      aria-label="Username"
                      aria-describedby="search_partner_id"
                      value={partnerId}
                      onChange={(e) => setPartnerId(e.target.value)}
                    />

                    {/* Input field shown on icon click in mobile */}
                  </div>
                </form>
              )}
            </Col>

            <Col className="d-none d-sm-block"></Col>
            <Col
              md={1}
              sm={12}
              className="d-none d-md-flex align-items-center justify-content-md-center"
            >
              <FontAwesomeIcon
                icon={toggleScreen ? faExpand : faCompress}
                style={{ fontSize: "20px", color: "white" }}
                onClick={toggleScreen ? openFullscreen : exitFullscreen}
              />
            </Col>
            <Col className="d-flex justify-content-center justify-content-md-end" sm ={12} md={1} xs={2}>
              <ul className="list-unstyled topbar-right-menu  bg-dark ">
                {authUser ? (
                  <NavigationAuth
                    authUser={authUser}
                    authUserSet={authUserSet}
                    history={history}
                  />
                ) : (
                  <NavigationNonAuth />
                )}
              </ul>
            </Col>
          </div>
          {/* <div className="app-search">
          <form>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <span className="mdi mdi-magnify"></span>
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div> */}
        </div>
      </div>
      {showInput && (
        // <input
        //   type="number"
        //   className="form-control d-md-none" // Visible only on mobile
        //   placeholder={`Enter ${isPID ? "PID" : "BID"}`}
        //   aria-label="Username"
        //   aria-describedby="search_partner_id"
        //   value={partnerId}
        //   onChange={(e) => {
        //     isPID ? setPartnerId(e.target.value) : setBookingId(e.target.value)
            
        //   }}
        //   onKeyDown={handleKeyDown} // Handle Enter key
        //   autoFocus // Auto-focus input when shown
        // />
        <div className="d-flex justify-content-center">
          <form className="p-3 w-75 border" >
            {/* <input type="search" className="form-control" placeholder="Search ..." aria-label="Recipient's username" /> */}
            <form onSubmit={handleBookingSearch}>
              <input
                type="number"
                className="form-control d-md-none" // Visible only on mobile
                placeholder={`Enter BID`}
                aria-label="Username"
                aria-describedby="search_booking_id"
                value={bookingId}
                onChange={(e) => {
                  setBookingId(e.target.value)
                }}
                autoFocus // Auto-focus input when shown
              />
            </form>
            <form onSubmit={handleSubmit}>
              <input
                type="number"
                className="form-control d-md-none" // Visible only on mobile
                placeholder={`Enter PID`}
                aria-label="Username"
                aria-describedby="search_partner_id"
                value={partnerId}
                onChange={(e) => {
                  setPartnerId(e.target.value)
                }}
                autoFocus // Auto-focus input when shown
              />
            
            </form>
          </form>
        </div>
      )}
    </>
  );
};

const NavigationAuth = ({ authUser, authUserSet, history }) => {
  const logout = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("authToken");
    authUserSet(null);
    history.push("/");
  };
  return (
    <>
      <Dropdown as={CustomDropdown} className="bg-none">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components w-50">
          <span className="account-user-avatar ">
            {/* <img
              src={
                authUser.image
                  ? authUser.image
                  : "https://coox-new.s3.ap-south-1.amazonaws.com/images/default/cook/cook_img.jpg"
              }
              alt="user"
              className="rounded-circle h-50 w-25"
            /> */}

            <img
              src={
                authUser.image
                  ? authUser.image
                  : "https://coox-new.s3.ap-south-1.amazonaws.com/images/default/cook/cook_img.jpg"
              }
              alt="user"
              className="rounded-circle h-50 w-25 d-none d-sm-block"  // Default for non-xs
            />
            <img
              src={
                authUser.image
                  ? authUser.image
                  : "https://coox-new.s3.ap-south-1.amazonaws.com/images/default/cook/cook_img.jpg"
              }
              alt="user"
              className="rounded-circle img-fluid img-size-xs d-block d-sm-none"  // For xs screens
            />

          </span>
          {/* <span className="my-2">
            <h4 className="account-user-name font-weight-bold mb-0 text-white ml-2 mt-1">
              {authUser.name || authUser.mobile}
            </h4>
            <small className="text-white ml-2 ">{authUser.roles[0]}</small>
          </span>
          <span>
            {" "}
            <FontAwesomeIcon icon={faCaretDown} />
          </span> */}


          {/* Hide this part on xs screen only */}
          <span className="d-none d-md-inline my-2">
            <h4 className="account-user-name font-weight-bold mb-0 text-white ml-2 mt-1">
              {authUser.name || authUser.mobile}
            </h4>
            <small className="text-white ml-2">{authUser.roles[0]}</small>
          </span>

          <span className="d-none d-md-inline">
            {" "}
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          <Link
            to={{ pathname: authUser.login_token }}
            target="_blank"
            className="dropdown-item"
          >
            <i className="mdi mdi-login mr-1"></i>
            <span>Login to Website</span>
          </Link>
          <Link to={ROUTES.CHANGEPASSWORD} className="dropdown-item">
            <i className="mdi mdi-account-edit mr-1"></i>
            <span>Change Password</span>
          </Link>

          <Link to="#!" className="dropdown-item" onClick={() => logout()}>
            <i className="mdi mdi-logout mr-1"></i>
            <span>Logout</span>
          </Link>
          {/* <Dropdown.Item eventKey='1'>Red</Dropdown.Item>
				<Dropdown.Item eventKey='2'>Blue</Dropdown.Item>
				<Dropdown.Item eventKey='3' active>
					Orange
				</Dropdown.Item>
				<Dropdown.Item eventKey='1'>Red-Orange</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      {/* <li className='dropdown notification-list'>
			<a
				className='nav-link dropdown-toggle nav-user arrow-none mr-0'
				data-toggle='dropdown'
				id='topbar-userdrop'
				href='#'
				role='button'
				aria-haspopup='true'
				aria-expanded='false'
			>
				<span className='account-user-avatar'>
					<img
						src='https://coox-new.s3.ap-south-1.amazonaws.com/images/default/cook/cook_img.jpg'
						alt='user-image'
						className='rounded-circle'
					/>
				</span>
				<span>
					<span className='account-user-name'>{authUser.name}</span>
					<span className='account-position'>{authUser.roles[0]}</span>
				</span>
			</a>
			<div
				className='dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown'
				aria-labelledby='topbar-userdrop'
			>
				
				<div className=' dropdown-header noti-title'>
					<h6 className='text-overflow m-0'>Welcome !</h6>
				</div>

				
				<a href='#' className='dropdown-item notify-item'>
					<i className='mdi mdi-account-circle mr-1'></i>
					<span>My Account</span>
				</a>

				
				<a href='#' className='dropdown-item notify-item'>
					<i className='mdi mdi-account-edit mr-1'></i>
					<span>Settings</span>
				</a>

				
				<a href='#' className='dropdown-item notify-item'>
					<i className='mdi mdi-lifebuoy mr-1'></i>
					<span>Support</span>
				</a>

				
				<a href='#' className='dropdown-item notify-item'>
					<i className='mdi mdi-lock-outline mr-1'></i>
					<span>Lock Screen</span>
				</a>

				
				<a href='#' className='dropdown-item notify-item'>
					<i className='mdi mdi-logout mr-1'></i>
					<span>Logout</span>
				</a>
			</div>
		</li> */}
      {/* <li className='dropdown notification-list'>
			<SignOutButton />
		</li> */}
    </>
  );
};

const NavigationNonAuth = () => (
  <>
    {/* <li className="dropdown notification-list">
      <Link className="nav-link" to={ROUTES.LANDING}>
        <span className="noti-icon">Landing</span>
      </Link>
    </li> */}
    <li className="dropdown notification-list">
      <Link className="nav-link" to={ROUTES.LOGIN}>
        <span className="noti-icon">Sign In</span>
      </Link>
    </li>
  </>
);

const mapStateToProps = (state) => ({
  authUser: state.login.authUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Navigation);
