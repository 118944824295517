export const LOGIN = "/login";
export const LANDING = "/";
export const HOME = "/home";
export const EDITCOOK = "/edit-cook/:id";
export const FINDCOOK = "/find-cook";
export const COOKSTAGES = "/cook-stages";
export const ASSIGNEDCOOKS = "/assigned-cooks";
export const USERACCESS = "/user-access";
export const CHANGEPASSWORD = "/change-password";
export const BOOKINGSDASHBOARD = "/booking-dashboard";
export const SALESDASHBOARD = "/sales-dashboard";
export const SUPPLYDASHBOARD = "/supply-dashboard";
export const BOOKINGCALENDAR = "/booking-calendar";
export const COINDASHBOARD = "/coin-dashboard";
export const CAKESDASHBOARD = "/cake-dashboard";
export const DECORATIONSDASHBOARD = "/decoration-dashboard";
export const HOTELSDASHBOARD = "/hotel-dashboard";
export const DISHDASHBOARD = "/dish-dashboard";
export const LOGINSDASHBOARD = "/logins-dashboard";
export const DISH = "/dish/:id";
export const INGREDIENTSDASHBOARD = "/ingredient-dashboard";
export const APPLIANCESDASHBOARD = "/appliance-dashboard";
export const CUISINESANDCOMBOSDASHBOARD = "/controller";
export const COOKEDITHISTORY = "/cook-edit-history/:id";
export const HELPERSDASHBOARD = "/helpers-dashboard";
export const BRANDSDASHBOARD = "/brands-dashboard";
export const SHOPSDASHBOARD = "/shops-dashboard";
export const PENALTIESDASHBOARD = "/penalties-dashboard";
export const PICTURESDASHBOARD = "/picture-dashboard";
export const TOP25COOKS = "/top-25-cooks";
export const COUPONSDASHBOARD = "/coupons-dashboard";
export const KITSDASHBOARD = "/kits-dashboard";
export const MONTHLYVIEW = "/monthly-view";
export const CONTACTDASHBOARD = "/contacts-dashboard";
export const HOWZZAT = "/howzzat-dashboard";
export const BEEHIVE = "/beehive-dashboard";
export const STATS = "/stats-dashboard"
export const PARTNERTEST = "/partner-test"